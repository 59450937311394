import { defineStore } from "pinia";
import { useApiUtilStore } from '@/stores/apiutil-st';
export const useBaseStore = defineStore({
  id: "base",
  persist: true,
  state: () => ({
    error: null,
    errors: [],
    global_error: null,
    message: null,
  }),
  getters: {
    busy: (state) => useApiUtilStore().busy, // (sugar) borrow from api store
    has_error: (state) => state.error || (state.errors && state.errors.length > 0 ) ? true : false,
    has_message: (state) => state.message ? true : false,
  },
  actions: {
    /**
     * Clears the `error`, `errors`, and `message` state variables.
     */
    clearMessageAndErrors(){
      this.error = null;
      this.errors = [];
      this.message = null;
    },
    /**
     * Briefly set a message before resetting it to empty after a certain number of seconds.
     * @param {string} msg message to set
     * @param {number} timeout_s timeout to reset message to empty in seconds.
     */
    flashMessage(msg, timeout_s){
      this.message = msg;
      setTimeout(()=>{ this.message = null; }, timeout_s*1000 );
    },
    
  },
});
