import { defineStore } from "pinia";
import { callApi as makeApiCall, ApiError, ApiNotAvailableError, ApiAuthorizationError } from '@/lib/api';
// import { useAuthStore } from './auth-st';
import { useBaseStore } from './base-st';


export const useApiUtilStore = defineStore({
  id: "api",
  persist: true,
  state: () => ({
    in_process: 0,
  }),
  getters: {
    busy: (state) => state.in_process > 0,
  },
  actions: { 
    increment() { this.in_process = 1; },
    decrement() { this.in_process = 0; },
    /**
     * Invoke an API call (using underlying axios-related tooling).
     * Data is returned from the action so it can be used downstream.
     * Along the way, if any errors occur with the API call, global error and message state is updated.
     * The api call also increments the in-process counter and "busy" state is updated.
     * @param {*} callOptions
     * @param {string} method
     * @param {string} url
     * @param {object} parms
     * @param {string} body 
     * @param {string} selector (optional) name of entity property on expected data body response to return instead of the whole data response
     * @param {number} flash (optional). A nonzero number indicates a timeout duration (in seconds) that a message should be set into store before being cleared.
     * @param {string} jwt at times, a JWT may be required for accessing certain APIs. 
     * Note, since the message is a global property, flashing can potentially clear out state of other messages that are occuring concurrently.
     *  
     * @returns {object} of the form:
     * @example {success: true, data: {}, message: "", error: "" }
     */
    async callApi(callOptions, loading = true){
      // let {method, url, parms, body, selector, flash} = callOptions
      // const authStore = useAuthStore();
      const baseStore = useBaseStore();
      let result = {success: false, data: undefined, message: null, error: null};
      try{
        baseStore.clearMessageAndErrors();
        if(loading) this.increment();
        let jwt = callOptions && callOptions.jwt ? callOptions.jwt : null;
        let data = await makeApiCall(jwt, callOptions);
        
        result.success = true;

        if(data && data.message){
          if(!callOptions.flash){
            baseStore.message = data.message;
          } else {
            baseStore.flashMessage( data.message, callOptions.flash );
          }
        }

        if(callOptions.selector){
          result.data = data[callOptions.selector];
        } else {
          result.data = data;
        }

      }catch(ex){
        result.success = false;
        
        // console.error('error handling in store: %o \n  %s', ex.name, ex.message);
        if(ex instanceof ApiNotAvailableError){
          console.error(ex);
          result.message = 'Network error.';
          baseStore.global_error = ex.message;
          
        } else if(ex instanceof ApiError){
          if(ex instanceof ApiAuthorizationError){
            result.message = 'Unauthorized.';
            // const authStore = useAuthStore();
            // authStore.incrementErrorCount();

          } else if(ex.data){
            console.error(ex);
            console.error(ex.data);
            // generally, ApiErrors carry data and can be parsed
            result.data = ex.data;
            
            if(ex.data.message){
              result.message = ex.data.message 
              baseStore.message = ex.data.message;
            }

            if(ex.data.error){
              result.error = ex.data.error 
              baseStore.error = ex.data.error;
            }
            
            if(ex.data.errors){
              baseStore.error = ex.data.errors;
            }
            
            if(ex.data.errors) baseStore.error = ex.data.errors;// If using Apigrate backend, data.errors are readable field level errors for ssvalidation.
          }
        } else {
          console.error(ex);
          result.error = ex.message;
          baseStore.error =  `Error. ${ex.message}`;
        } 
      } finally {
        this.decrement();
        return result;
      }
    },
  }
});
