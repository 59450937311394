import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '@/views/Landing.vue'
import NotFound from '@/views/NotFound.vue'
import Product from '@/views/Product.vue'
import Search from '@/views/Search.vue'
import About from '@/views/About.vue'
import Sourcing from '@/views/Sourcing.vue'
import General from '@/views/General.vue'

import Login from '@/views/Login.vue'
import ResetPassword from '@/views/ResetPassword.vue'


import Accessories from '@/views/Accessories/Accessories.vue'
import SPO2 from '@/views/Accessories/SPO2.vue'
import ECG from '@/views/Accessories/ECG.vue'
import EKG from '@/views/Accessories/EKG.vue'
import NIBP from '@/views/Accessories/NIBP.vue'
import Temp from '@/views/Accessories/Temp.vue'
import IBP from '@/views/Accessories/IBP.vue'
import Fetal from '@/views/Accessories/Fetal.vue'
import ETCO2 from '@/views/Accessories/ETCO2.vue'
import Multiparameter from '@/views/Accessories/Multiparameter.vue'
import EEG from '@/views/Accessories/EEG.vue'
import O2 from '@/views/Accessories/O2.vue'
import Orthopedics from '@/views/Accessories/Orthopedics.vue'
import Batteries from '@/views/Accessories/Batteries.vue'
import Anasthesia from '@/views/Accessories/Anasthesia.vue'
import Vetmonitoring from '@/views/Accessories/Vetmonitoring.vue'

import Parts from '@/views/Parts/Parts.vue'
import Pumps from '@/views/Parts/Pumps.vue'
import Telemetry from '@/views/Parts/Telemetry.vue'
import Monitor from '@/views/Parts/Monitor.vue'
import Transducers from '@/views/Parts/Transducers.vue'

import Equipment from '@/views/Equipment.vue'
import Downloads from '@/views/Downloads.vue'
import FAQ from '@/views/FAQ.vue'

import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsOfUse from '@/views/TermsOfUse.vue'
import TrademarkNotice from '@/views/TrademarkNotice.vue'
// function loadView(view) {
//   return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`)
// }

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/product/:product_id',
    name: 'Product',
    component: Product,
    props: true
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: true
  }, 
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true
  },
  
  {
    path: "/reset/:token",
    name: "ResetPassword",
    component: ResetPassword,
    props: route => ({
      token: route.params.token
    })
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    props: true
  },
  {
    path: '/sourcing',
    name: 'Sourcing',
    component: Sourcing,
    props: true
  },
  {
    path: '/general',
    name: 'General',
    component: General,
    props: true
  },
  {
    path: '/accessories',
    name: 'Accessories',
    component: Accessories,
    props: true,
  },
  {
    path: '/accessories/spo2',
    name: 'SPO2',
    component: SPO2,
    props: true,
  },
  {
    path: '/accessories/ecg',
    name: 'ECG',
    component: ECG,
    props: true,
  },
  {
    path: '/accessories/ekg',
    name: 'EKG',
    component: EKG,
    props: true,
  },
  {
    path: '/accessories/nibp',
    name: 'NIBP',
    component: NIBP,
    props: true,
  },
  {
    path: '/accessories/temp',
    name: 'Temp',
    component: Temp,
    props: true,
  },
  {
    path: '/accessories/ibp',
    name: 'IBP',
    component: IBP,
    props: true,
  },
  {
    path: '/accessories/temp',
    name: 'Temp',
    component: Temp,
    props: true,
  },
  {
    path: '/accessories/fetal',
    name: 'Fetal',
    component: Fetal,
    props: true,
  },
  {
    path: '/accessories/etco2',
    name: 'ETCO2',
    component: ETCO2,
    props: true,
  },
  {
    path: '/accessories/multiparameter',
    name: 'Multiparameter',
    component: Multiparameter,
    props: true,
  },
  {
    path: '/accessories/eeg',
    name: 'EEG',
    component: EEG,
    props: true,
  },
  {
    path: '/accessories/o2',
    name: 'O2',
    component: O2,
    props: true,
  },
  {
    path: '/accessories/orthopedics',
    name: 'Orthopedics',
    component: Orthopedics,
    props: true,
  },
  {
    path: '/accessories/batteries',
    name: 'Batteries',
    component: Batteries,
    props: true,
  },
  {
    path: '/accessories/anesthesia',
    name: 'Anasthesia',
    component: Anasthesia,
    props: true,
  },
  {
    path: '/accessories/vetmonitoring',
    name: 'Vetmonitoring',
    component: Vetmonitoring,
    props: true,
  },

  {
    path: '/parts',
    name: 'Parts',
    component: Parts,
    props: true,
  },
  {
    path: '/parts/pumps',
    name: 'Pumps',
    component: Pumps,
    props: true,
  },
  {
    path: '/parts/telemetry',
    name: 'Telemetry',
    component: Telemetry,
    props: true,
  }, 
  {
    path: '/parts/monitor',
    name: 'Monitor',
    component: Monitor,
    props: true,
  },
  {
    path: '/parts/transducers',
    name: 'Transducers',
    component: Transducers,
    props: true,
  },

  {
    path: '/equipment',
    name: 'Equipment',
    component: Equipment,
    props: true,
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads,
    props: true,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    props: true,
  }, 

  {
    path: '/trademark-notice',
    name: 'TrademarkNotice',
    component: TrademarkNotice,
    props: true,
  }, 
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse,
    props: true,
  }, 
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    props: true,
  }, 
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: loadView('About')
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  // },
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  }
]
export default routes
